import React, { useRef, useEffect } from "react";
import { Col, Row, notification, BackTop } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  GoogleOutlined,
  CloseCircleOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";

import { COMPANY_DATA, NOTIFICATION_STYLE } from "../../utils/constants/index";
import Footer from "../../components/footer";
import Section1 from "../../components/section1";
import Section2 from "../../components/section2";
import Section3 from "../../components/section3";
import Section4 from "../../components/section4";
import Section5 from "../../components/section5";
import Section6 from "../../components/section6";
import Section7 from "../../components/section7";
import "./style.css";
import Section0 from "../../components/section0";

const useMountEffect = (fun) => useEffect(() => fun);

const Home = () => {
  const openNotification = (notificationType) => {
    notification.open({
      message: (
        <>
          {notificationType === "mail" && (
            <span className="info">
              <b>E-mail:</b>&nbsp; {COMPANY_DATA.EMAIL}
            </span>
          )}
          {notificationType === "whatsapp" && (
            <span className="info">
              <b>WhatsApp:</b>&nbsp; {COMPANY_DATA.PHONE_NUMBER}
            </span>
          )}
        </>
      ),
      style: NOTIFICATION_STYLE,
      closeIcon: <CloseCircleOutlined />,
    });
  };

  const catalogRef = useRef(null);
  const commandesRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToCatalog = () => catalogRef.current.scrollIntoView();
  const scrollToCommandes = () => commandesRef.current.scrollIntoView();
  const scrollToContact = () => contactRef.current.scrollIntoView();

  useMountEffect(scrollToCatalog);
  useMountEffect(scrollToCommandes);
  useMountEffect(scrollToContact);

  return (
    <div className="container">
      <Row className="top-line">
        <Row className="social-media">
          <Col
            span={4}
            className="social-media-icon-top-wrapper"
            onClick={() => openNotification("mail")}
          >
            <GoogleOutlined className="social-media-icon" />
          </Col>
          <Col span={4} className="social-media-icon-top-wrapper">
            <a href={COMPANY_DATA.FACEBOOK}>
              <FacebookOutlined className="social-media-icon" />
            </a>
          </Col>
          <Col span={4} className="social-media-icon-top-wrapper">
            <a href={COMPANY_DATA.INSTAGRAM}>
              <InstagramOutlined className="social-media-icon" />
            </a>
          </Col>
          <Col
            span={4}
            className="social-media-icon-top-wrapper"
            onClick={() => openNotification("whatsapp")}
          >
            <WhatsAppOutlined className="social-media-icon" />
          </Col>
        </Row>
      </Row>
      <Row className="header">
        <div className="bg"></div>

        <Col span={4} className="logo"></Col>
        <Col span={16} style={{ paddingLeft: "5%" }}>
          <div className="box-right">
            <Row>
              <Col span={6} className="menu">
                Accueil
              </Col>
              <Col span={6} className="menu" onClick={scrollToCatalog}>
                Catalogue
              </Col>
              <Col span={6} className="menu" onClick={scrollToCommandes}>
                &nbsp;&nbsp;Commandes
              </Col>
              <Col span={6} className="menu last" onClick={scrollToContact}>
                Contact
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="box-left">
        <Section0></Section0>
      </div>
      <div className="box-left">
        <Section1></Section1>
      </div>
      <div className="box-right">
        <Section2></Section2>
      </div>
      <div ref={catalogRef}>
        <Section3></Section3>
      </div>
      <div ref={commandesRef}>
        <Section4></Section4>
      </div>
      <div ref={contactRef}>
        <Section6></Section6>
      </div>
      <div>
        <Section7></Section7>
      </div>
      <div ref={contactRef}>
        <Section5></Section5>
        <Footer></Footer>
      </div>
      <div className="backToTopLayer">
        <BackTop>
          <div className="backToTop">
            <UpCircleOutlined className="backToTopArrow" />
          </div>
        </BackTop>
      </div>
    </div>
  );
};

export default Home;
