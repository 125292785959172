import React from "react";
import Carousel from "../../shared/carousel";

import { IMAGES } from "../../utils/constants/index";
import "./style.css";

const Section1 = () => (
  <>
    <h1 className="primary-title"> Miss Dattes</h1>
    <p className="primary-wrapper">
      " Une nouvelle façon pour goutter les dattes ! "
    </p>
    <div className="section1">
      <p className="divider-paragraph-sec3">
        M<b>aster Class Dattes Fourrées Prestige</b>: Inscrivez-vous au Master
        Class des Dattes Fourrées prestige 💯% Pratique. La formation inclus les
        cahiers de recettes, les certificats ainsi que les boîtes de
        dégustation. ( les photos) Les places sont <b>Limitées</b>
      </p>
      <Carousel images={IMAGES.section1} />
      <p style={{ marginTop: "20px" }} className="divider-paragraph-sec3">
        Réservez votre Place sur: <b>06 76 92 27 23 👈</b>
      </p>
    </div>
  </>
);

export default Section1;
