import React from "react";
import { Divider } from "antd";
import { ShoppingOutlined } from "@ant-design/icons";

import Carousel from "../../shared/carousel";
import { IMAGES } from "../../utils/constants";
import "./style.css";

const Section3 = () => (
  <div className="section3">
    <h1 className="primary-title">Notre Catalogue</h1>
    <div className="divider-paragraph-sec3">
      <p>
        Chez Miss Dattes, toutes nos créations sont 100% Handmade et 100%
        Healthy. Nous proposons des saveurs uniques préparées par Amour. Nos
        dattes Majhoul sont fourrées avec des Amandes croquantes et
        caramélisées, et enrobées de Chocolat gourmands. Chaque datte vous fera
        voyager dans un rêve de douceur et de gourmandise.
      </p>
      <Divider />
      <h3 className="title">
        <ShoppingOutlined /> &nbsp; Nos riches saveurs
      </h3>
      <Carousel images={IMAGES.section3} isS={true} />
    </div>
    <h1 className="primary-title">L'art de nos créations</h1>
    <div className="divider-paragraph-sec3">
      <div className="img-container">
        <img src={IMAGES.section31[0]} alt="" className="exmp" />
        <Divider />
        <img src={IMAGES.section31[3]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section31[1]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section31[2]} alt="" className="exmp-n" />
      </div>
    </div>
  </div>
);

export default Section3;
