import { Divider } from "antd";
import React from "react";
import { IMAGES } from "../../utils/constants";
import Carousel from "../../shared/carousel";

import img from "./../../assets/who-are-we.jpeg";
import "./style.css";

const Section2 = () => (
  <div className="section2">
    <h1 className="secondary-title">
      Miss Dattes vous offre des Cadeaux Healthy pour tous vos événements.
    </h1>
    <div className="secondary-wrapper">
      <p>
        📌 Dattes 💯% Medjhoul <br /> 📌 Saveurs Uniques <br /> 📌 Livraison à
        domicile <br />
        👉 Commandez par MD ou par whatsapp 0676 92 27 23
      </p>
      <div className="section1">
        <Carousel images={IMAGES.sectionCadeaux} />
      </div>
    </div>
    <h1 className="secondary-title" style={{textAlign: "center"}}> 100% Fait à main</h1>
    <div className="secondary-wrapper">
      <div className="container-img-waw">
        <img src={img} alt="" className="img-waw" />
      </div>
    </div>
    <h1 className="secondary-title">Special Fiançailles & Mariage</h1>
    <div className="secondary-wrapper">
      <p>
        Découvrez nos coffrets Fiançailles et Mariage sublimés avec nos Dattes
        Fourrées prestigieuse 100% Medjhoul Royal. Offrez Miss Dattes et donnez
        du goût à vos fêtes
      </p>
      <div className="img-container">
        <img src={IMAGES.section21[0]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section21[1]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section22[2]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section22[1]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section22[0]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section45[0]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section45[1]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section45[2]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section45[3]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section45[6]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section45[7]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section45[5]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section45[4]} alt="" className="exmp-n" />
        <Divider />
      </div>
      <div className="section1">
        <Carousel images={IMAGES.section44} />
      </div>
    </div>
  </div>
);

export default Section2;
