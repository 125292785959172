import { isEmpty } from "lodash";
import React from "react";
import Slider from "react-slick";

import Spin from "../spinner";

const settings = {
  autoplay: true,
  autoplaySpeed: 2500,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Carousel = ({ images = [] }) => (
  <>
    {!isEmpty(images) && (
      <Slider {...settings}>
        {images.map((image) => (
          <>
            <img src={image} alt="" className="contentStyle" key={image} />
          </>
        ))}
      </Slider>
    )}
    {isEmpty(images) && <Spin />}
  </>
);

export default Carousel;
