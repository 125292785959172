import { Divider } from "antd";
import React from "react";
import Carousel from "../../shared/carousel";
import { IMAGES } from "../../utils/constants";

import "./style.css";

const Section6 = () => (
  <div className="section">
    <h1 className="primary-title">Nos Offres spéciales</h1>
    <div className="wrapper-of">
      <p>
        <b>M</b>iss <b>D</b>attes Vous Offres des packs pour tout événement et
        tout besoin: Marriage, Annivairsaire. . , aussi des formidables
        combinaisons pour des cadeaux entre amis
      </p>
      <Divider />
      <Carousel images={IMAGES.section6.carousel} />
    </div>
  </div>
);

export default Section6;
