import React from "react";
import { Divider } from "antd";

import {} from "./../../utils/constants/index";
import Carousel from "../../shared/carousel";
import { IMAGES } from "../../utils/constants";
import "./style.css";

const Section4 = () => (
  <div className="section4">
    <h1 className="secondary-title">Vos commandes</h1>
    <div className="secondary-wrapper">
      <p>
        Commandez votre Coffret à distance de n'importe où vous êtes au Maroc.
        Que se soit par Whatsapp, via les réseaux sociaux ou notre site internet
        , notre équipe est disponible 7j/7 pour répondre à votre demande.
      </p>
      <Divider />
      <Carousel images={IMAGES.section4.carousel} />
      <Divider />
      <h5 className="title-secondary"> &nbsp; L'art qu'on maitrise !</h5>
      <Divider />
      <div className="img-container">
        <img src={IMAGES.section4.other[0]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section4.other[1]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section4.other[2]} alt="" className="exmp-n" />
        <Divider />
        <img src={IMAGES.section4.other[3]} alt="" className="exmp-n" />
      </div>
    </div>
  </div>
);

export default Section4;
