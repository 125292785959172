import React from "react";
import Carousel from "../../shared/carousel";
import { IMAGES } from "../../utils/constants";

import "./style.css";

const Section0 = () => (
  <div className="section0">
    <h1 className="secondary-title">Nos Saveurs</h1>
    <div className="secondary-wrapper">
      <p>
        Cher amateur de saveur raffinées, Plongez dans le monde luxueux de nos
        Dattes Prestige au goûts unique à base de Safran, Café, Framboise et
        Amandes croustillant. Chaque bouchée est une fusion raffinée des
        saveurs. Une expérience gustative unique avec les Dattes de Miss Dattes.
      </p>
      <div className="section1">
        <Carousel images={IMAGES.sectionSaveurs} />
      </div>
    </div>
  </div>
);

export default Section0;
