import React from "react";

import vc from "./../../assets/visit-card.jpeg";
import delivery from "./../../assets/delivery.svg";
import command from "./../../assets/command.svg";
import "./style.css";
import { ShoppingCartOutlined } from "@ant-design/icons";

const Section5 = () => (
  <div className="section5">
    <h1 className="primary-title">LIVRAISON</h1>
    <div className="wrapper-of">
      <p>
        Chez Miss Dattes on vous assure la Fraîcheur et la qualité du produit
        demandé en temps!
      </p>
    </div>
    <div className="divider-paragraph-sec3">
      <img src={delivery} alt="" className="delivery" />
      <img src={vc} alt="" className="visit-card" />
    </div>
    <h1 className="primary-title mtop">COMMANDEZ SUR NOTRE PLATFORME</h1>
    <h2 className="primary-colored center">(Comming Soon !)</h2>
    <div className="divider-paragraph-sec3">
      <img src={command} alt="" className="delivery" />
    </div>
    <div className="wrapper-of">
      <p>
        Afin d'aider notre client a prendre la décision façe a nos packs et
        options on offre une interface facile a manipuler pour faire le bon
        choix <ShoppingCartOutlined className="primary-colored" />
      </p>
    </div>
  </div>
);

export default Section5;
