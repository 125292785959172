import amnd from "./../../assets/amnd.jpg";
import ananas from "./../../assets/ananas.jpg";
import art from "./../../assets/art.jpg";
import cad0 from "./../../assets/cadeaux-0.jpeg";
import cad1 from "./../../assets/cadeaux-1.jpeg";
import cad2 from "./../../assets/cadeaux-2.jpeg";
import cad3 from "./../../assets/cadeaux-3.jpeg";
import cad4 from "./../../assets/cadeaux-4.jpeg";
import cad5 from "./../../assets/cadeaux-5.jpeg";
import cad6 from "./../../assets/cadeaux-6.jpeg";
import cad7 from "./../../assets/cadeaux-7.jpeg";
import cad8 from "./../../assets/cadeaux-8.jpeg";
import cad9 from "./../../assets/cadeaux-9.jpeg";
import cajou from "./../../assets/cajou.jpg";
import chb from "./../../assets/chb.jpg";
import flv10 from "./../../assets/flv10.jpeg";
import flv2 from "./../../assets/flv2.jpeg";
import flv3 from "./../../assets/flv3.jpeg";
import flv4 from "./../../assets/flv4.jpeg";
import flv5 from "./../../assets/flv5.jpeg";
import flv8 from "./../../assets/flv8.jpeg";
import flv9 from "./../../assets/flv9.jpeg";
import jd0 from "./../../assets/jd0.jpg";
import jd1 from "./../../assets/jd1.jpg";
import jd10 from "./../../assets/jd10.jpg";
import jd2 from "./../../assets/jd2.jpg";
import jd3 from "./../../assets/jd3.jpg";
import jd4 from "./../../assets/jd4.jpg";
import jd5 from "./../../assets/jd5.jpg";
import jd6 from "./../../assets/jd6.jpg";
import jd7 from "./../../assets/jd7.jpg";
import jd8 from "./../../assets/jd8.jpg";
import jd9 from "./../../assets/jd9.jpg";
import long12 from "./../../assets/long12.jpg";
import long14 from "./../../assets/long14.jpg";
import long15 from "./../../assets/long15.jpg";
import long2 from "./../../assets/long2.jpg";
import long4 from "./../../assets/long4.jpg";
import long8 from "./../../assets/long8.jpg";
import mariage0 from "./../../assets/mariage0.jpg";
import mariage1 from "./../../assets/mariage1.jpg";
import mc0 from "./../../assets/master-class-0.jpeg";
import mc1 from "./../../assets/master-class-1.jpeg";
import mc2 from "./../../assets/master-class-2.jpeg";
import mc3 from "./../../assets/master-class-3.jpeg";
import mc4 from "./../../assets/master-class-4.jpeg";
import mf0 from "./../../assets/mf0.jpg";
import mf1 from "./../../assets/mf1.jpg";
import mf2 from "./../../assets/mf2.jpg";
import n1 from "./../../assets/n1.jpeg";
import n2 from "./../../assets/n2.jpeg";
import nw1 from "./../../assets/nw1.jpg";
import nw3 from "./../../assets/nw3.jpg";
import offer3 from "./../../assets/offer3.jpg";
import offer4 from "./../../assets/offer4.jpg";
import pch from "./../../assets/pch.jpg";
import rmdn from "./../../assets/rmdn.jpg";
import rmdn1 from "./../../assets/rmdn1.jpg";
import rmdn2 from "./../../assets/rmdn2.jpg";
import rmdn3 from "./../../assets/rmdn3.jpg";
import rmdn4 from "./../../assets/rmdn4.jpg";
import sec311 from "./../../assets/sec311.jpeg";
import sec312 from "./../../assets/sec312.jpeg";
import sec313 from "./../../assets/sec313.jpeg";
import sec314 from "./../../assets/sec314.jpg";
import video from "./../../assets/video.PNG";
import sav0 from "./../../assets/sav0.jpg";
import sav1 from "./../../assets/sav1.jpg";
import sav2 from "./../../assets/sav2.jpg";
import sav3 from "./../../assets/sav3.jpg";
import sav4 from "./../../assets/sav4.jpg";
import sav5 from "./../../assets/sav5.jpg";
import sav6 from "./../../assets/sav6.jpg";
import sav7 from "./../../assets/sav7.jpg";
import sav8 from "./../../assets/sav8.jpg";
import sav9 from "./../../assets/sav9.jpg";
import sav10 from "./../../assets/sav10.jpg";
import sav11 from "./../../assets/sav11.jpg";

export const COMPANY_DATA = {
  PHONE_NUMBER: "+212 676 922 723",
  EMAIL: "miss.dattes1@gmail.com",
  FACEBOOK: "https://www.facebook.com/miss.dattes/",
  INSTAGRAM: "https://www.instagram.com/miss.dattes?r=nametag",
  ADDRESS: "Maroc",
};

export const NOTIFICATION_STYLE = {
  backgroundColor: "white",
  fontFamily: "'abel', 'cursive'",
  fontSize: "20px",
  color: "#cca300",
};

export const IMAGES = {
  // section1: [img12, img6],
  section1: [mc3, mc1, mc2, mc0, mc4],
  section22: [mf0, mf1, mf2],
  section21: [mariage0, mariage1],
  section44: [jd0, jd1, jd7, jd8, jd10, jd9],
  section45: [jd2, jd4, jd5, jd6, jd3, n1, n2],
  section3: [
    flv2,
    flv3,
    flv4,
    flv5,
    flv8,
    flv9,
    flv10,
    amnd,
    cajou,
    ananas,
    chb,
    pch,
  ],
  section31: [sec311, sec312, sec313, sec314],
  section4: {
    carousel: [offer3, offer4, nw1],
    other: [art, long12, long14, nw3],
  },
  section6: {
    carousel: [long2, long4, long8],
    other: [long15, long12, long14],
  },
  section7: {
    carousel: [rmdn, rmdn1, rmdn2, rmdn3],
    other: [rmdn4],
  },
  sectionCadeaux: [cad0, cad1, cad2, cad3, cad4, cad5, cad6, cad7, cad8, cad9],
  sectionSaveurs: [sav0, sav1, sav2, sav3, sav4, sav5, sav6, sav7, sav8, sav9, sav10, sav11],
};

export const VIDEOS = {
  tiktok: video,
};

export const COPYRIGHT = {
  YEAR: new Date(Date.now()).getFullYear(),
  COMPANY: "Miss Dattes",
};
