import React from "react";
import { Divider } from "antd";

import {} from "./../../utils/constants/index";
import Carousel from "../../shared/carousel";
import { IMAGES } from "../../utils/constants";
import "./style.css";

const Section7 = () => (
  <div className="section4">
    <h1 className="secondary-title">Spécial Ramadan</h1>
    <div className="secondary-wrapper">
      <p>
        Miss Dattes vous offres une sélection sucré Special Ramadan pour
        couronner votre Table de Ramadan. 100% Healthy, 100% Amandes et 100%
        Miel pur.
      </p>
      <div className="img-container">
        <video controls className="video-d">
        <source src="https://v16-webapp.tiktok.com/04c5ce96ca47f8c6459f50d621c9059e/625b1def/video/tos/alisg/tos-alisg-pve-0037c001/4fc5d8b254124fac912fdf504595a20c/?a=1988&br=1412&bt=706&cd=0%7C0%7C0%7C0&ch=0&cr=0&cs=0&dr=0&ds=3&er=&ft=eXd.6HpoMyq8Z328Lwe2NPY0yl7Gb&l=2022041613494201019105322115D6D9D6&lr=tiktok_m&mime_type=video_mp4&net=0&pl=0&qs=0&rc=M3dtZzs6ZjlpOzMzODczNEApZjtoOzZmOTw8Nzw8OTM0OGczNW5pcjRnampgLS1kMS1zczZgMzIvNjReYS82LjY0YDA6Yw%3D%3D&vl=&vr=" type="video/mp4" />
        </video>
        <img src={IMAGES.section7.other[0]} alt="" className="exmp7" />
      </div>
      <Divider />
      <Carousel images={IMAGES.section7.carousel}/>
    </div>
  </div>
);

export default Section7;
